const messages = [
    {
        path: '/messages',
        name: 'messages.index',
        component: () => import('@/pages/Message/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Messages | B-ZONE ADMIN',
        }
    },
    {
        path: '/messages/create',
        name: 'messages.create',
        component: () => import('@/pages/Message/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Create Message | B-ZONE ADMIN',
        }
    },

    {
        path: '/messages/:id/show',
        name: 'messages.show',
        component: () => import('@/pages/Message/EditPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Edit Message | B-ZONE ADMIN',
        }
    },
]

export default messages