const services = [
    {
        path: '/services',
        name: 'services.index',
        component: () => import('@/pages/Service/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Services | B-ZONE ADMIN',
        }
    },
    {
        path: '/services/create',
        name: 'services.create',
        component: () => import('@/pages/Service/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Create a Service | B-ZONE ADMIN',
        }
    },

    {
        path: '/services/:id/show',
        name: 'services.show',
        component: () => import('@/pages/Service/EditPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Edit Service | B-ZONE ADMIN',
        }
    },
]

export default services