import axios from 'axios'

const state = {
    category: {},
    categories: {},
    category_errors: {},
    category_loading: false,
}

const getters = {
    category: state => state.category,
    categories: state => state.categories,
    category_errors: state => state.category_errors,
    category_loading: state => state.category_loading,
}

const mutations = {
    setCategory(state, category) {
        state.category = category
    },
    setCategories(state, categories) {
        state.categories = categories
    },
    setCategoryErrors(state, category_errors) {
        state.category_errors = category_errors
    },
    setCategoryLoading(state, category_loading) {
        state.category_loading = category_loading
    },
}

const actions = {
    async storeCategory({commit}, data) {
        commit('setCategoryLoading', true)
        commit('setCategoryErrors', {})
        try {
            let response = await axios.post(`/api/categories/store`, data)
            commit('setCategoryLoading', false)
            commit('setCategoryErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCategoryLoading', false)
            commit('setCategoryErrors', error.response.data.errors)
            return false
        }
    },
    async getCategories({commit}, params) {
        commit('setCategoryLoading', true)
        try {
            let response = await axios.get(`/api/categories`, {params})
            commit('setCategories', response.data)
            commit('setCategoryLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCategoryLoading', false)

            return false
        }
    },
    async getAllCategories({commit}, data) {
        commit('setCategoryLoading', true)
        try {
            let response = await axios.get(`/api/categories/all`, data)
            commit('setCategories', response.data)
            commit('setCategoryLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCategoryLoading', false)

            return false
        }
    },
    async getCategory({commit}, id) {
        try {
            let response = await axios.get(`/api/categories/${id}/show`)
            commit('setCategory', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },

    async destroyCategory({commit}, id) {
        commit('setCategoryLoading', true)
        try {
            let response = await axios.delete(`/api/categories/${id}/destroy`)
            commit('setCategoryLoading', false)

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCategoryLoading', false)
            return false
        }
    },
    async updateCategory({commit}, data) {
        commit('setCategoryLoading', true)
        commit('setCategoryErrors', {})
        try {
            let response = await axios.put(`/api/categories/${data.id}/update`, data)
            commit('setCategoryLoading', false)
            commit('setCategoryErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setCategoryLoading', false)
            commit('setCategoryErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}