import axios from 'axios'

const state = {
    message: {},
    messages: {},
    message_errors: {},
    message_loading: false,
}

const getters = {
    message: state => state.message,
    messages: state => state.messages,
    message_errors: state => state.message_errors,
    message_loading: state => state.message_loading,
}

const mutations = {
    setMessage(state, message) {
        state.message = message
    },
    setMessages(state, messages) {
        state.messages = messages
    },
    setMessageErrors(state, message_errors) {
        state.message_errors = message_errors
    },
    setMessageLoading(state, message_loading) {
        state.message_loading = message_loading
    },
}

const actions = {
    async storeMessage({commit}, data) {
        commit('setMessageLoading', true)
        commit('setMessageErrors', {})
        try {
            let response = await axios.post(`/api/messages/store`, data)
            commit('setMessageLoading', false)
            commit('setMessageErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setMessageLoading', false)
            commit('setMessageErrors', error.response.data.errors)
            return false
        }
    },
    async getMessages({commit}, params) {
        commit('setMessageLoading', true)
        try {
            let response = await axios.get(`/api/messages`, {params})
            commit('setMessages', response.data)
            commit('setMessageLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setMessageLoading', false)

            return false
        }
    },

    async getMessage({commit}, id) {
        try {
            let response = await axios.get(`/api/messages/${id}/show`)
            commit('setMessage', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },

    async destroyMessage({commit}, id) {
        commit('setMessageLoading', true)
        try {
            let response = await axios.delete(`/api/messages/${id}/destroy`)
            commit('setMessageLoading', false)

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setMessageLoading', false)
            return false
        }
    },
    async updateMessage({commit}, data) {
        commit('setMessageLoading', true)
        commit('setMessageErrors', {})
        try {
            let response = await axios.put(`/api/messages/${data.id}/update`, data)
            commit('setMessageLoading', false)
            commit('setMessageErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setMessageLoading', false)
            commit('setMessageErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}