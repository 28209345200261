import faqs from "@/router/faqs";
import services from "@/router/services";
import barbers from "@/router/barbers";
import categories from "@/router/categories";
import messages from "@/router/messages";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'B-ZONE ADMIN',
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Index/LoginPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: 'B-ZONE ADMIN',
        }
    },
    ...faqs,
    ...services,
    ...barbers,
    ...categories,
    ...messages,

    {
        path: '/work-images',
        name: 'work_images.index',
        component: () => import('@/pages/WorkImage/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'content',
            title: 'Works | B-ZONE ADMIN',
        }
    },

    {
        path: '/settings',
        name: 'settings.index',
        component: () => import('@/pages/Setting/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'content',
            title: 'Works | B-ZONE ADMIN',
        }
    },
]

export default routes