const barbers = [
    {
        path: '/barbers',
        name: 'barbers.index',
        component: () => import('@/pages/Barber/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Barbers | B-ZONE ADMIN',
        }
    },
    {
        path: '/barbers/create',
        name: 'barbers.create',
        component: () => import('@/pages/Barber/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Create a Barber | B-ZONE ADMIN',
        }
    },

    {
        path: '/barbers/:id/show',
        name: 'barbers.show',
        component: () => import('@/pages/Barber/EditPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Edit Barber | B-ZONE ADMIN',
        }
    },
]

export default barbers