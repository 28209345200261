const categories = [
    {
        path: '/categories',
        name: 'categories.index',
        component: () => import('@/pages/Category/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Categories | B-ZONE ADMIN',
        }
    },
    {
        path: '/categories/create',
        name: 'categories.create',
        component: () => import('@/pages/Category/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Create a Category | B-ZONE ADMIN',
        }
    },

    {
        path: '/categories/:id/show',
        name: 'categories.show',
        component: () => import('@/pages/Category/EditPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Edit Category | B-ZONE ADMIN',
        }
    },
]

export default categories