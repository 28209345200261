const faqs = [
    {
        path: '/faqs',
        name: 'faqs.index',
        component: () => import('@/pages/FAQ/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'FAQs | B-ZONE ADMIN',
        }
    },
    {
        path: '/faqs/create',
        name: 'faqs.create',
        component: () => import('@/pages/FAQ/CreatePage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Create FAQ | B-ZONE ADMIN',
        }
    },

    {
        path: '/faqs/:id/show',
        name: 'faqs.show',
        component: () => import('@/pages/FAQ/EditPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Edit FAQ | B-ZONE ADMIN',
        }
    },
]

export default faqs